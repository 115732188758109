import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const token = localStorage.getItem("token");
    if (token === null) {
      return [null, 401];
    } else {
      const response = await axios.post(link, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = response.data;
      return [result, response.status];
    }
  } catch (error) {
    return [error["response"]["data"], error["response"]["status"]];
  }
};

export const cryptoPayment_api = async (hash, invoice_id) => {
  const formData = {
    hash: hash,
    invoice_id: invoice_id,
  };
  const data = await fetchDataFromLink(
    `${base_url}/invoice/crypto_payment`,
    formData
  );
  return data;
};

export default cryptoPayment_api;
