import React, { useEffect, useState } from "react";
import allinvoiceinfo_api from "../../api/AllInvoiceInfo";
import moment from "moment-jalaali";

function Invoices() {
  const [invoices_elements, SetInvoicesElements] = useState(<></>);
  const handleNumberFormatter = (number, multiply = 1) => {
    return (number * multiply).toLocaleString();
  };
  const convertGregorianToJalali = (gregorianDate) => {
    return moment(gregorianDate).format("jYYYY/jMM/jDD");
  };
  const AllInvoices_data = async () => {
    const invoices_data = await allinvoiceinfo_api();
    if (invoices_data[1] === 200) {
      SetInvoicesElements(
        invoices_data[0].data.map((invoice) => (
          <div
            dir="rtl"
            key={invoice.id}
            style={{
              opacity:
                moment(invoice.createdAt).unix() > moment().unix() - 86400
                  ? 1
                  : 0.3,
              pointerEvents:
                moment(invoice.createdAt).unix() > moment().unix() - 86400
                  ? "auto"
                  : "none",
            }}
            className="bg-[#292929] w-[300px] rounded-xl flex flex-col gap-[10px] p-5 cursor-pointer"
          >
            <div className="flex flex-col gap-[10px]">
              <span className="text-[#fff] text-[24px] font-bold">
                {invoice.department === "plans_boughts"
                  ? "خرید اشتراک"
                  : invoice.department === "plans_boughts"
                    ? "خرید دوره"
                    : invoice.department === "charge"
                      ? "شارژ حساب"
                      : "نامشخص"}
              </span>
              <span className="text-[#A9A9A9]">
                قابل پرداخت:{" "}
                <span className="text-[#fff]">
                  {invoice.currency === "TMN_RECEIPT"
                    ? handleNumberFormatter(
                        invoice.full_value - invoice.discount + invoice.tax,
                        invoices_data[0].metadata.crypto_price
                      )
                    : handleNumberFormatter(
                        invoice.full_value - invoice.discount + invoice.tax
                      )}{" "}
                  {invoice.currency === "USDT" ? "تتر" : "تومان"}
                </span>
              </span>
            </div>
            <span className="text-[#A9A9A9]">
              تاریخ ایجاد:{" "}
              <span className="text-[#fff]">
                {convertGregorianToJalali(invoice.createdAt)}
              </span>
            </span>
            <span className="text-[#A9A9A9]">
              وضعیت پرداخت:{" "}
              {invoice.status === "pending" ? (
                <span className="text-[#FF9901] p-2 bg-[#ff990144] rounded-[10px]">
                  پرداخت نشده
                </span>
              ) : invoice.status === "success" ? (
                <span className="text-[#189470] p-2 bg-[#18947045] rounded-[10px]">
                  پرداخت شده
                </span>
              ) : (
                <span className="text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                  نا مشخص
                </span>
              )}
            </span>

            {invoice.status === "pending" ? (
              moment(invoice.createdAt).unix() > moment().unix() - 86400 ? (
                <span
                  className="mt-5 text-[#fff] p-2 bg-[#7E42FF] rounded-[10px]"
                  onClick={() => {
                    if (invoice.status === "pending") {
                      window.location.href = `/invoice/payment/${invoice._id}`;
                    }
                  }}
                >
                  پرداخت
                </span>
              ) : (
                <span className="mt-5 text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                  منقضی شده
                </span>
              )
            ) : invoice.status === "success" ? (
              <span className="mt-5 text-[#189470] p-2 bg-[#18947045] rounded-[10px] opacity-50">
                پرداخت شده
              </span>
            ) : (
              <span className="mt-5 text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                منقضی شده
              </span>
            )}
          </div>
        ))
      );
    } else if (invoices_data[1] === 401) {
      window.location.href = "/Login?call_back=/dashboard";
    }
  };

  useEffect(() => {
    AllInvoices_data();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-[60px] dark">
      <span className="font-bold text-[24px]">فاکتور ها</span>
      <div className="w-full flex flex-row flex-wrap justify-center items-center gap-[30px]">
        {invoices_elements}
      </div>
    </div>
  );
}

export default Invoices;
