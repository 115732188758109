import { Bitcoin, User, LogoutCurve } from "iconsax-react";
import style from "./Navbar.module.css";
import { Link } from "react-router-dom";

function Navbar({ phone, name }) {
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  const handleDashboard = () => {
    window.location.href = "/";
  };
  return (
    <div className={style.navBody}>
      <div className={style.navbar}>
        <div className={style.navLeft}>
          <div onClick={handleDashboard} className={style.Bitcoin}>
            <h1>DaniiBTC</h1>
            <Bitcoin className={style.Bitcoinicon} size="22" color="#7E42FF" />
          </div>
        </div>
        <div className={style.navRight}>
          {phone === null && name === null ? (
            <Link
              className={style.headerLoginButton}
              style={{ marginLeft: 10 }}
              to="/Login"
            >
              <User className="cursor-pointer" size="19" color="#ffffff" />
              ورود یا ثبت نام
            </Link>
          ) : (
            <div className="flex flex-row-reverse gap-[10px] items-center">
              <Link
                className={style.headerLoginButton}
                style={{ marginLeft: 10 }}
                to={
                  window.location.href.includes("dashboard")
                    ? "/"
                    : "/dashboard"
                }
              >
                {window.location.href.includes("dashboard")
                  ? "بازگشت به سایت"
                  : "داشبورد"}
              </Link>
              <LogoutCurve className="cursor-pointer" onClick={handleLogout} />
            </div>
          )}
          {/* <ul className={style.menuItemsPc}>
            <li>
              <span className={style.new}>جدید</span>
              مشاوره
            </li>
            <li>
              <span className={style.new}>جدید</span>
              دوره رایگان ترید
            </li>
            <li>نظر دانشجویان</li>
          </ul> */}
        </div>
      </div>
      {/* <ul style={{ margin: "auto" }} className={style.menuItemsMobile}>
        <li>
          <span className={style.new}>جدید</span>
          مشاوره
        </li>
        <li>
          <span className={style.new}>جدید</span>
          دوره رایگان ترید
        </li>
        <li>نظر دانشجویان</li>
      </ul> */}
      <br className="block md:hidden" />
    </div>
  );
}
export default Navbar;
