import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const response = await fetch(link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    return [result, response.status];
  } catch (error) {
    return [error.message || "Unknown error", error.status || 500];
  }
};

export const login_api = async (phone) => {
  const formData = {
    phone: phone,
  };
  const data = await fetchDataFromLink(`${base_url}/user/login`, formData);
  return data;
};

export default login_api;
