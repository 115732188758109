import style from "./dashboard.module.css";
import { ToastContainer, toast } from "react-toastify";
import Userinfo from "../DashboardComponents/userinfo";
import Invoices from "../DashboardComponents/invoices";
import PlansBought from "../DashboardComponents/plans_bought";
import Courses from "../DashboardComponents/courses";

function Dashboard({ page, SetUserInfo }) {
  const menuItemsElements = [
    {
      page_url: "/userinfo",
      element: <Userinfo SetUserInfo={SetUserInfo} />,
    },
    { page_url: "/invoices", element: <Invoices /> },
    { page_url: "/subscriptions", element: <PlansBought toast={toast} /> },
    { page_url: "/courses", element: <Courses /> },
  ];
  const currentPage = menuItemsElements.find((item) => item.page_url === page);
  return (
    <div className="w-full h-full">
      <ToastContainer theme="dark" rtl={true} position="bottom-right" />
      {currentPage ? currentPage.element : null}
      {page === "/dashboard" ? (window.location.href = "/dashboard") : null}
    </div>
  );
}

export default Dashboard;
