import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import TypeConsultation from "./pages/typeConsultation/TypeConsultation";
import Counseling from "./pages/counseling/Counseling";
import Date from "./pages/date/Date";
import Course from "./pages/Course/course";
import FreeCourseRegister from "./pages/FreeCourseRegister/FreeCourseRegister";
import InvoicePayment from "./pages/invoicePayment/invoice_payment";
import Dashbaord from "./pages/dashbaord/dashboard";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Counseling" element={<Counseling />} />
      <Route path="/TypeConsultation" element={<TypeConsultation />} />
      <Route path="/Date" element={<Date />} />
      <Route path="/free_course" element={<FreeCourseRegister />} />
      <Route
        path="/dashboard"
        element={<Dashbaord />}
        children={<Route path="/dashboard/:page" />}
      />
      <Route
        path="/courses"
        element={<Course />}
        children={<Route path="/courses/:id" />}
      />
      <Route
        path="/invoice/payment"
        element={<InvoicePayment />}
        children={<Route path="/invoice/payment/:id" />}
      />
    </Routes>
  );
}

export default App;
