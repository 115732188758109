import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import {
  User,
  ReceiptText,
  StatusUp,
  Teacher,
  Bitcoin,
  Add,
  Home,
} from "iconsax-react";
import { useParams } from "react-router-dom";
import Dashboard from "../../component/Dashboard/dashboard";

const NAVIGATION = [
  {
    kind: "header",
    title: "منو",
  },
  {
    segment: "userinfo",
    title: "اطلاعات کاربر",
    icon: <User />,
  },
  {
    segment: "invoices",
    title: "فاکتور ها",
    icon: <ReceiptText />,
  },
  {
    segment: "subscriptions",
    title: "اشتراک ها",
    icon: <StatusUp />,
  },
  {
    segment: "courses",
    title: "دوره ها",
    icon: <Teacher />,
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  palette: {
    mode: "dark",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Dana, Gilroy",
  },
});

function DemoPageContent({ pathname, SetUserInfo }) {
  if (pathname === "/") {
    window.location.href = "/";
  }
  return (
    <Box
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Dashboard page={pathname} SetUserInfo={SetUserInfo} />
    </Box>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function Dashbaord(props) {
  const { page } = useParams();
  const page_id = page !== undefined ? page : "userinfo";

  const { windowr } = props;

  const [pathname, setPathname] = React.useState(`/${page_id}`);

  const [session, setSession] = React.useState({
    user: {
      name: "ناشناس",
      email: "",
      image: "",
      balance: 0,
    },
  });
  const authentication = React.useMemo(() => {
    return {
      signOut: () => {
        localStorage.removeItem("token");
        window.location.href = "/";
      },
    };
  }, []);
  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = windowr !== undefined ? windowr() : undefined;
  const Balance = () => {
    return (
      <div className="flex flex-row-reverse gap-[5px]">
        <div
          className="bg-[#292929] p-2 rounded-full text-[#A9A9A9] cursor-pointer"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <Home className="m-1 rounded-full" />
        </div>
        <div
          dir="rtl"
          className="flex flex-row gap-[5px] h-full bg-[#292929] p-2 rounded-full items-center text-[#A9A9A9] cursor-pointer"
          onClick={() => {
            window.location.href = "/invoice/payment/charge";
          }}
        >
          <span>موجودی: {session.user.balance}$</span>
          <Add className="bg-[#505050] m-1 rounded-full" />
        </div>
      </div>
    );
  };
  return (
    // preview-start
    <AppProvider
      branding={{
        title: <h1 className="hidden lg:block">DaniiBTC</h1>,
        logo: <Bitcoin className="hidden lg:block" size="30" color="#7E42FF" />,
      }}
      session={session}
      authentication={authentication}
      navigation={NAVIGATION}
      router={router}
      theme={demoTheme}
      windowr={demoWindow}
    >
      <DashboardLayout slots={{ toolbarActions: Balance }}>
        <DemoPageContent pathname={pathname} SetUserInfo={setSession} />
      </DashboardLayout>
    </AppProvider>
    // preview-end
  );
}

Dashbaord.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  windowr: PropTypes.func,
};

export default Dashbaord;
