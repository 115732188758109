import { base_url } from "../config/config";
import axios from "axios";

const fetchDataFromLink = async (link, data) => {
  try {
    const token = localStorage.getItem("token");
    if (token === null) {
      return [null, 401];
    } else {
      const response = await axios.post(link, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = response.data;
      return [result, response.status];
    }
  } catch (error) {
    return [error["response"]["data"]["error"], error["response"]["status"]];
  }
};

export const userInfoEdit_api = async (name = null, profile = null) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("profile", profile);

  const data = await fetchDataFromLink(`${base_url}/user/edit`, formData);
  return data;
};

export default userInfoEdit_api;
