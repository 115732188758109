import React, { useEffect, useState } from "react";
import allcoursesinfo_api from "../../api/AllCoursesInfo";

function Courses() {
  const [courses_elements, SetInvoicesElements] = useState(<></>);

  const AllInvoices_data = async () => {
    const courses_data = await allcoursesinfo_api();
    if (courses_data[1] === 200) {
      SetInvoicesElements(
        courses_data[0].data.map((course) => (
          <div
            dir="rtl"
            key={course.id}
            className="bg-[#292929] w-[300px] rounded-xl flex flex-col gap-[10px] cursor-pointer"
            onClick={() => {
              window.location.href = `/courses/${course._id}`;
            }}
          >
            <img className="w-full rounded-t-xl" src={course.tumb} alt="" />
            <div className="w-full px-5 py-2 flex flex-col items-start gap-[5px]">
              <span className="font-bold text-[20px] text-[#fff]">
                {course.name}
              </span>
              <span className="text-justify text-[14px] text-[#A9A9A9]">
                {course.desc}
              </span>
            </div>
          </div>
        ))
      );
    } else if (courses_data[1] === 401) {
      window.location.href = "/Login?call_back=/dashboard";
    }
  };

  useEffect(() => {
    AllInvoices_data();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-[60px] dark">
      <span className="font-bold text-[24px]">دوره ها</span>
      <div className="w-full flex flex-row flex-wrap justify-center items-center gap-[30px]">
        {courses_elements}
      </div>
    </div>
  );
}

export default Courses;
