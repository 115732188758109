import React, { useEffect, useState } from "react";
import allplansboughtinfo_api from "../../api/AllPlansBoughtInfo";
import moment from "moment-jalaali";
import boughtPlansActive_api from "../../api/boughtPlansActive";
import { cn } from "../../lib/utils";

function PlansBought({ toast }) {
  const [plans_bought_elements, SetPlansBoughtElements] = useState(<></>);
  const handleNumberFormatter = (number, multiply = 1) => {
    return (number * multiply).toLocaleString();
  };
  const convertGregorianToJalali = (gregorianDate) => {
    return moment(gregorianDate).format("jYYYY/jMM/jDD");
  };
  const active_plan = async (bought_plan_id) => {
    const active_data = await boughtPlansActive_api(bought_plan_id);
    if (active_data[1] === 200) {
      toast.success("فعال شد");
      AllPlansBought_data();
    } else if (active_data[1] === 403) {
      toast.error(active_data[0]);
    } else if (active_data[1] === 404) {
      toast.error("پلن پیدا نشد");
    } else if (active_data[1] === 500) {
      toast.error(
        "مشکلی سمت سرور پیش آمده مجدد امتحان کنید یا با پشتیبانی تماس بگیرید"
      );
    } else {
      toast.error("مشکلی پیش آمده");
    }
  };
  const AllPlansBought_data = async () => {
    const plans_bought_data = await allplansboughtinfo_api();
    if (plans_bought_data[1] === 200) {
      SetPlansBoughtElements(
        plans_bought_data[0].data
          .map((plan_b) => (
            <div
              dir="rtl"
              key={plan_b.id}
              style={{
                opacity:
                  plan_b.daysleft > 0 || plan_b.daysleft === -1 ? 1 : 0.3,
                pointerEvents:
                  plan_b.daysleft > 0 || plan_b.daysleft === -1
                    ? "auto"
                    : "none",
              }}
              className={cn(
                "bg-[#292929] w-[300px] rounded-xl flex flex-col gap-[10px] p-5 cursor-pointer",
                {
                  "opacity-100 pointer-events-auto":
                    plan_b.daysleft > 0 || plan_b.daysleft === -1,
                  "opacity-20 pointer-events-none": plan_b.daysleft === 0,
                }
              )}
            >
              <div className="flex flex-col gap-[10px]">
                <span className="text-[#fff] text-[24px] font-bold">
                  اشتراک{" "}
                  {plan_b.plan_time > 0
                    ? `${plan_b.plan_time} روزه`
                    : "مادام العمر"}
                </span>
                <span className="text-[#A9A9A9]">
                  روز باقی مانده:{" "}
                  <span className="text-[#fff]">
                    {plan_b.plan_time > 0
                      ? `${plan_b.daysleft} روز`
                      : "مادام العمر"}
                  </span>
                </span>
              </div>

              <span className="text-[#A9A9A9]">
                وضعیت فعال سازی:{" "}
                {plan_b.daysleft > 0 || plan_b.daysleft === -1 ? (
                  plan_b.active === false ? (
                    <span className="text-[#FF9901] p-2 bg-[#ff990144] rounded-[10px]">
                      فعال نشده
                    </span>
                  ) : plan_b.active === true ? (
                    <span className="text-[#189470] p-2 bg-[#18947045] rounded-[10px]">
                      فعال شده
                    </span>
                  ) : (
                    <span className="text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                      نا مشخص
                    </span>
                  )
                ) : (
                  <span className="text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                    اتمام مهلت
                  </span>
                )}
              </span>
              {plan_b.daysleft > 0 || plan_b.daysleft === -1 ? (
                plan_b.active === false ? (
                  <span
                    className="mt-5 text-[#fff] p-2 bg-[#7E42FF] rounded-[10px]"
                    onClick={async () => {
                      await active_plan(plan_b._id);
                    }}
                  >
                    فعال سازی
                  </span>
                ) : plan_b.active === true ? (
                  <span
                    onClick={() => {
                      window.open(plan_b.invite_link, "_blank");
                    }}
                    className="mt-5 text-[#fff] p-2 bg-[#7E42FF] rounded-[10px]"
                  >
                    لینک عضویت
                  </span>
                ) : (
                  <span className="mt-5 text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                    غیرفعال
                  </span>
                )
              ) : (
                <span className="mt-5 text-[#A9A9A9] p-2 bg-[#a9a9a945] rounded-[10px]">
                  اتمام مهلت
                </span>
              )}
            </div>
          ))
          .reverse()
      );
    } else if (plans_bought_data[1] === 401) {
      window.location.href = "/Login?call_back=/dashboard";
    }
  };

  useEffect(() => {
    AllPlansBought_data();
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-[60px] dark">
      <span className="font-bold text-[24px]">اشتراک ها</span>
      <span className="text-[#A9A9A9] text-[18px] px-20">
        زمان باقی مانده اشتراک های خریداری شده بعد از فعال سازی اشتراک محاسبه
        خواهد شد
      </span>
      <div className="w-full flex flex-row flex-wrap justify-center items-center gap-[30px]">
        {plans_bought_elements}
      </div>
    </div>
  );
}

export default PlansBought;
