import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../component/navbar/Navbar";
import userinfo_api from "../../api/UserInfo";
import invoiceinfo_api from "../../api/InvoiceInfo";
import { Label } from "../../component/ui/label";
import { Input } from "../../component/ui/input";
import { cn } from "../../lib/utils";
import backgroundImage from "../../assetes/img/9D6kyBfmgiv4HD4HjZDrVWpBhI.jpg";
import { Copy, TickCircle, Add } from "iconsax-react";
import cryptoPayment_api from "../../api/cryptoPayment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

function InvoicePayment() {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const { id } = useParams();
  const [pay_el, setPayEl] = useState(<></>);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableClose, setDisableClose] = useState(true);
  const scrollRef = useRef(null);
  const [selected, setSelected] = useState("crypto");
  const [balance, setBalance] = useState(0);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleCopy = (text) => {
    toast.success("متن کپی شد.");
    navigator.clipboard.writeText(text);
  };
  const handleNumberFormatter = (number, multiply = 1) => {
    return (number * multiply).toLocaleString();
  };
  const handleClickOpenDialog = () => {
    setDisableClose(true);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleScroll = () => {
    const scrollElement = scrollRef.current;

    const scrollTop = Math.ceil(scrollElement.scrollTop);
    const scrollHeight = Math.floor(scrollElement.scrollHeight);
    const clientHeight = Math.floor(scrollElement.clientHeight);

    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    console.log([scrollHeight, scrollTop, clientHeight]);
    console.log(isAtBottom);
    setDisableClose(!isAtBottom);
  };
  const cryptoPayment_submit = async (selected) => {
    const acceptRules = document.getElementById("accept_rules").checked;
    if (acceptRules) {
      const hashId =
        selected === "balance"
          ? "balance"
          : document.getElementById("hash_id").value;
      if (hashId.length > 0) {
        if (hashId.length > 10 || hashId === "balance") {
          const payment_data = await cryptoPayment_api(hashId, id);
          if (payment_data[1] === 200) {
            toast.success(payment_data[0].message);
            window.location.href = "/dashboard";
          } else if (payment_data[1] === 406) {
            toast.error(payment_data[0].message);
          } else if (payment_data[1] === 426) {
            toast.error(payment_data[0].message);
          } else if (payment_data[1] === 401) {
            toast.error("مجدد لاگین کنید");
            setTimeout(() => {
              window.location.href = `/Login?call_back=/invoice/payment/${id}`;
            }, 1.5);
          } else if (payment_data[1] === 403) {
            toast.error("این فاکتور برای شما نیست");
            setTimeout(() => {
              window.location.href = "/";
            }, 1.5);
          } else {
            console.log(payment_data);
            if (payment_data?.[0].errors?.[0]?.msg) {
              toast.error(payment_data[0].errors[0].msg);
            } else {
              toast.error("مشکلی پیش آمده");
              setTimeout(() => {
                window.location.href = "/";
              }, 1.5);
            }
          }
        } else {
          toast.error("مقدار Hash نمیتواند کوتاه باشد");
        }
      } else {
        toast.error("مقدار Hash نمیتواند خالی باشد");
      }
    } else {
      toast.error("ابتدا قوانین رو مطالعه کنید و سپس تایید کنید");
    }
  };
  const invoice_data = async () => {
    if (id !== "charge") {
      const inv_data = await invoiceinfo_api(id);
      if (inv_data[1] === 200) {
        const json_data = inv_data[0].data;
        const json_metadata = inv_data[0].metadata;
        if (json_data.currency === "USDT") {
          setPayEl(
            <div dir="rtl" className="dark flex flex-col items-center">
              <span className="font-bold text-[40px] text-[white] my-8">
                خرید {json_data.title}
              </span>
              <div className="flex rounded-lg bg-[#313131] p-[5px] gap-[5px]">
                <button
                  onClick={() => setSelected("crypto")}
                  className={`px-4 py-2 rounded-lg transition-all duration-300 text-[white] m-0
          ${selected === "crypto" ? "border-2 border-[#7E42FF] bg-[#0F0F0F]" : "border-2 border-transparent bg-transparent"}`}
                >
                  پرداخت کریپتو
                </button>

                <button
                  onClick={() => setSelected("balance")}
                  className={`px-4 py-2 rounded-lg transition-all duration-300 text-[white] m-0
          ${selected === "balance" ? "border-2 border-[#7E42FF] bg-[#0F0F0F]" : "border-2 border-transparent bg-transparent"}`}
                >
                  پرداخت از موجودی
                </button>
              </div>
              {selected !== "balance" ? (
                <div className="flex flex-col mt-[20px] items-center gap-[10px]">
                  <img
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&margin=10&data=${json_metadata.crypto_address}`}
                    alt=""
                  />
                  <span
                    onClick={() => handleCopy(json_metadata.crypto_address)}
                    className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
                  >
                    {json_metadata.crypto_address} <Copy />
                  </span>
                  <div className="flex gap-[10px] text-[#A9A9A9]">
                    <span>{json_metadata.crypto_network}</span>
                    <span className="font-bold">
                      {json_metadata.crypto_symbol}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="mt-5">
                  <span className="text-[white] text-[18px] p-[10px] bg-[#313131] rounded-lg">
                    موجودی: {balance} تتر
                  </span>
                </div>
              )}
              <div className="flex flex-col gap-[5px] items-center mt-5">
                <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                  <span className="text-[white]">
                    {handleNumberFormatter(json_data.full_value)}
                  </span>{" "}
                  <span className="text-[white]">{json_data.currency}</span>{" "}
                  <span className="text-[#A9A9A9]">مبلغ کل:</span>
                </span>
                <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                  <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                  <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
                </div>
                <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                  <span className="text-[white]">{json_data.discount}</span>{" "}
                  <span className="text-[white]">{json_data.currency}</span>{" "}
                  <span className="text-[#A9A9A9]">تخفیف:</span>
                </span>
                <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                  <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                  <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
                </div>
                <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                  <span className="text-[white]">{json_data.tax}</span>
                  <span className="text-[white]">
                    {json_data.currency}
                  </span>{" "}
                  <span className="text-[#A9A9A9]">کارمزد:</span>
                </span>
                <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                  <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                  <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
                </div>
                <span className="flex flex-col-reverse items-center my-3 gap-[5px]">
                  <span
                    className="flex flex-row-reverse gap-[15px]"
                    style={{
                      direction: "rtl",
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "0% 10%",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textFillColor: "transparent",
                      color: "rgb(255, 255, 255)",
                      fontSize: "3em",
                      fontFamily: "dana",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      textAlign: "center",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <span>
                      {json_data.full_value > json_data.discount
                        ? json_data.full_value -
                          json_data.discount +
                          json_data.tax
                        : 0}
                    </span>{" "}
                    <span>{json_data.currency}</span>
                  </span>
                  <span className="text-[#A9A9A9]">مبلغ قابل پرداخت:</span>
                </span>
              </div>
              {balance <
                json_data.full_value - json_data.discount + json_data.tax &&
              selected === "balance" ? (
                <div className="flex flex-col items-center gap-[5px]">
                  <span className="text-[white] text-[18px]">
                    موجودی کافی نیست
                  </span>
                  <button
                    className="flex flex-row text-[white] text-[18] p-[7px] bg-[#7E42FF] rounded-lg"
                    onClick={() => {
                      window.location.href = "/invoice/payment/charge";
                    }}
                  >
                    افزایش موجودی <Add />
                  </button>
                </div>
              ) : (
                <div className="flex flex-col mt-[20px] mb-[90px] gap-[20px] w-[70%] min-w-[300px] max-w-[500px]">
                  {selected !== "balance" ? (
                    <LabelInputContainer className="mb-4">
                      <Label htmlFor="hash_id">مقدار Hash پرداخت</Label>
                      <Input
                        id="hash_id"
                        placeholder="مقدار Hash یا TXID"
                        type="text"
                      />
                    </LabelInputContainer>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    <Checkbox
                      {...label}
                      id="accept_rules"
                      sx={{
                        color: "#4F2A9C",
                        "&.Mui-checked": {
                          color: "#7E42FF",
                        },
                      }}
                      onClick={() => {
                        if (disableClose === false) {
                        } else {
                          toast.error("ابتدا قوانین را مطالعه کنید");
                          document.getElementById("accept_rules").checked =
                            false;
                        }
                      }}
                    />
                    <span className="text-[white]">
                      متن{" "}
                      <Link
                        className="underline"
                        onClick={handleClickOpenDialog}
                      >
                        قوانین
                      </Link>{" "}
                      را مطالعه کرده و تایید میکنم
                    </span>
                  </div>
                  <button
                    className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                    type="submit"
                    onClick={() => cryptoPayment_submit(selected)}
                  >
                    بررسی پرداخت
                    <BottomGradient />
                  </button>
                </div>
              )}
            </div>
          );
        } else if (json_data.currency === "TMN_RECEIPT") {
          setPayEl(
            <div dir="rtl" className="dark flex flex-col items-center">
              <div className="flex flex-col gap-[5px] items-center mt-5">
                <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                  <span className="text-[white]">{"تومان"}</span>{" "}
                  <span className="text-[white]">
                    {handleNumberFormatter(
                      json_data.full_value,
                      json_metadata.crypto_price
                    )}
                  </span>
                  <span className="text-[#A9A9A9]">مبلغ کل:</span>
                </span>
                <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                  <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                  <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
                </div>
                <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                  <span className="text-[white]">{"تومان"}</span>{" "}
                  <span className="text-[white]">
                    {handleNumberFormatter(
                      json_data.discount,
                      json_metadata.crypto_price
                    )}
                  </span>
                  <span className="text-[#A9A9A9]">تخفیف:</span>
                </span>
                <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                  <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                  <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
                </div>
                <span className="flex flex-row-reverse items-center my-3 gap-[5px]">
                  <span className="text-[white]">{"تومان"}</span>{" "}
                  <span className="text-[white]">
                    {handleNumberFormatter(
                      json_data.full_value > json_data.discount
                        ? json_data.tax
                        : 0,
                      json_metadata.crypto_price
                    )}
                  </span>
                  <span className="text-[#A9A9A9]">کارمزد:</span>
                </span>
                <div className="w-[50%] flex flex-row-reverse h-[2px] bg-[#505050]">
                  <div className="h-full left-0 w-1/2 bg-gradient-to-r from-[#1c1c1c]"></div>
                  <div className="h-full right-0 w-1/2 bg-gradient-to-l from-[#1c1c1c]"></div>
                </div>
                <span className="flex flex-col-reverse items-center my-3 gap-[5px]">
                  <span
                    className="flex flex-row-reverse gap-[15px]"
                    style={{
                      direction: "rtl",
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "0% 10%",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textFillColor: "transparent",
                      color: "rgb(255, 255, 255)",
                      fontSize: "3em",
                      fontFamily: "dana",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      textAlign: "center",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <span>{"تومان"}</span>
                    <span>
                      {json_data.full_value > json_data.discount
                        ? handleNumberFormatter(
                            json_data.full_value -
                              json_data.discount +
                              json_data.tax,
                            json_metadata.crypto_price
                          )
                        : 0}
                    </span>{" "}
                  </span>
                  <span className="text-[#A9A9A9]">مبلغ قابل پرداخت:</span>
                </span>
              </div>
              <div className="flex flex-col items-center gap-[10px] min-w-[300px] max-w-[400px] w-[50%] relative">
                <img
                  className="w-full h-full object-cover"
                  src={json_metadata.tmn_receipt_img}
                  alt=""
                />
                <div className="absolute bottom-[15px] left-0 right-0 flex flex-col items-center gap-[5px] w-[100%] z-10 text-[1rem] md:text-xl">
                  <span className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer">
                    {json_metadata.tmn_receipt_name}
                  </span>
                  <span
                    dir="ltr"
                    onClick={() =>
                      handleCopy(json_metadata.tmn_receipt_card_number)
                    }
                    className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
                  >
                    <Copy />
                    {json_metadata.tmn_receipt_card_number}
                  </span>
                  <span
                    onClick={() =>
                      handleCopy(json_metadata.tmn_receipt_card_sheba)
                    }
                    className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
                  >
                    {json_metadata.tmn_receipt_card_sheba} <Copy />
                  </span>
                </div>
              </div>
              <div className="flex flex-col my-[50px] gap-[20px] w-[70%] min-w-[300px] max-w-[500px]">
                <LabelInputContainer className="mb-4">
                  <Label htmlFor="hash_id">مقدار Hash پرداخت</Label>
                  <Input
                    id="hash_id"
                    placeholder="مقدار Hash یا TXID"
                    type="text"
                  />
                </LabelInputContainer>{" "}
                <button
                  className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                  type="submit"
                  onClick={() => cryptoPayment_submit()}
                >
                  ارسال رسید
                  <BottomGradient />
                </button>
              </div>
            </div>
          );
        }
      } else if (inv_data[1] === 401) {
        window.location.href = `/Login?call_back=/invoice/payment/${id}`;
      } else {
        toast("مشکلی پیش آمده");
      }
    } else {
      const inv_data = await invoiceinfo_api();
      if (inv_data[1] === 200) {
        const json_metadata = inv_data[0].metadata;
        setPayEl(
          <div dir="rtl" className="dark flex flex-col items-center">
            <span className="font-bold text-[40px] text-[white] my-8">
              شارژ حساب
            </span>
            <div className="flex flex-col mt-[20px] items-center gap-[10px]">
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&margin=10&data=${json_metadata.crypto_address}`}
                alt=""
              />
              <span
                onClick={() => handleCopy(json_metadata.crypto_address)}
                className="text-white font-bold flex flex-row items-center gap-[5px] cursor-pointer"
              >
                {json_metadata.crypto_address} <Copy />
              </span>
              <div className="flex gap-[10px] text-[#A9A9A9]">
                <span>{json_metadata.crypto_network}</span>
                <span className="font-bold">{json_metadata.crypto_symbol}</span>
              </div>
            </div>

            <div className="flex flex-col mt-[20px] mb-[90px] gap-[20px] w-[70%] min-w-[300px] max-w-[500px]">
              <LabelInputContainer className="mb-4">
                <Label htmlFor="hash_id">مقدار Hash پرداخت</Label>
                <Input
                  id="hash_id"
                  placeholder="مقدار Hash یا TXID"
                  type="text"
                />
              </LabelInputContainer>{" "}
              <div>
                <Checkbox
                  {...label}
                  id="accept_rules"
                  sx={{
                    color: "#4F2A9C",
                    "&.Mui-checked": {
                      color: "#7E42FF",
                    },
                  }}
                  onClick={() => {
                    if (disableClose === false) {
                    } else {
                      toast.error("ابتدا قوانین را مطالعه کنید");
                      document.getElementById("accept_rules").checked = false;
                    }
                  }}
                />
                <span className="text-[white]">
                  متن{" "}
                  <Link className="underline" onClick={handleClickOpenDialog}>
                    قوانین
                  </Link>{" "}
                  را مطالعه کرده و تایید میکنم
                </span>
              </div>
              <button
                className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                type="submit"
                onClick={() => cryptoPayment_submit()}
              >
                بررسی پرداخت
                <BottomGradient />
              </button>
            </div>
          </div>
        );
      } else if (inv_data[1] === 401) {
        window.location.href = "/Login?call_back=/invoice/payment/charge";
      } else {
        toast("مشکلی پیش آمده");
      }
    }
  };
  const first_data = async () => {
    const user_data = await userinfo_api();
    if (user_data[1] === 200) {
      setName(user_data[0].data.name);
      setPhone(user_data[0].data.phone);
      setBalance(user_data[0].data.balance);
    }
  };
  useEffect(() => {
    first_data();
    invoice_data();
  }, [disableClose, selected]);

  return (
    <div>
      <ToastContainer theme="dark" rtl={true} position="bottom-right" />
      <Navbar name={name} phone={phone} />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle dir="rtl" id="alert-dialog-title">
          {"قوانین عضویت در کانال سیگنال"}
        </DialogTitle>
        <DialogContent onScroll={handleScroll} ref={scrollRef}>
          <DialogContentText dir="rtl" id="alert-dialog-description">
            قوانین و شرایط عضویت در کانال سیگنال Meme House :<br />
            <br />
            <br />
            🔰عضویت در کانال Meme House به منزله مطالعه دقیق و قبول تمامی قوانین
            زیر می باشد👇🏻
            <br />
            <br />
            🤝 خیر مقدم خدمت متقاضیان عضویت در کانال Meme House
            <br />
            <br />
            🔴 قطعا آگاه هستین که هیچ گونه قطعیت در سیگنال ها و پالس هایی که
            ارایه میشوند در دنیای پرنوسان و پر ریسک کریپتو وجود ندارد و ما صرفا
            فعالیت و خرید های خودمان را معرفی میکنیم.
            <br />
            <br />✅ قطعا در شرایط پر ریسک و طوفانی بازار، اقدام به خرید و معرفی
            هیچ رمزارزی در کانال کریپتو پالس نخواهیم داشت.
            <br />
            <br />
            🟠 کانال Meme House صرفا در جهت رشد و افزاش دانش شما عزیزان در عرصه
            ی مارکت کریپتو طراحی شده، عزیزانی که عضویت کانال Meme House را تهیه
            میکنند آموزش های نحوه ترید را بصورت هدیه و رایگان دریافت میکنند.
            <br />
            <br />
            🟠 تحلیل‎ها و سیگنال‎های ارائه شده در کانال نتیجه‎ تلاش و بررسی‎های
            تکنیکال و فاندامنتال تیم تحلیل و ترید ” daniibtc ” می باشد. در کانال
            Meme House تمام تلاش ما بر این است که تجربیات و دانش تیم تحلیل
            مجموعه که در طول سال‌ها فعالیت در مارکت کریپتو کارنسی به دست آمده در
            جهت سودآوری بیشتر شما عزیزان در اختیار شما قرارگیرد.
            <br />
            <br />
            <br />
            🔴 تسلط بر مدیریت سرمایه و مدیریت سیگنال و تغییر نقطه ی ورود و استاپ
            لاس و اصلاحات لازمه در حین استفاده از سیگنال ها، لازمه ی حضور در
            کانال Meme House می باشد و در صورت عدم نسلط لطفا وارد هیچ کانال
            سیگنالی نشوید.
            <br />
            <br />
            🔴 تمرکز ما بر کیفیت سیگنالهای ارسالی است نه کمیت.
            <br />
            <br />
            🔻 تمام بازارهای مالی دارای ریسک می باشند و هیچ سیگنال یا تحلیلی
            100% درست نیست. این نکته را در نظر داشته باشید که بدون داشتن آگاهی و
            دانش در خصوص مارکت و بدون آشنایی با مباحث روانشناسی بازار و مدیریت
            سرمایه و ریسک میمکوین و شتکوین ها حتی با وجود بهترین سیگنال‎ها و
            تحلیل‎ها امکان ضرر کردن در بلند مدت وجود دارد. یک تریدر حرفه ای
            میداند که همه‎ سیگنال‎ها و روش‎های تکنیکال احتمال Fail شدن دارند.
            توصیه میکنیم دوستان بر اساس استراتژی معاملاتی شخصی خود و با در نظر
            گرفتن اصول مدیریت سرمایه از تحلیل‎ها استفاده و به سیگنال‎ها ورود
            کنند و حتما حد ضرر را رعایت نمایند.
            <br />
            مسئولیت سرمایه گذاری و استفاده از تحلیل‎ها و سیگنال‎ها بر عهده شخص
            تریدر میباشد.
            <br />
            <br />
            🔻 از طرف ما هیچ تضمینی برای کسب سود داده نمی شود، هر تریدر مسئول
            عملکرد خودش می باشد، عضویت در کانال Meme House از طرف شما به منزله
            قبول این قوانین محسوب شده و تیم تحلیل تکنیکال و فاندامنتال مجموعه
            دنی بیت ” کانال Meme House ” را از هر مسئولیت و ادعای قضایی مصون می
            دارد.
            <br />
            <br />
            🔴 وجوه پرداختی به هیچ عنوان عودت داده نمیشود.
            <br />
            <br />
            با تشکر ❤️🌹
            <br />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ lineHeight: 1 }}
            className="flex flex-row items-center gap-[5px]"
            onClick={handleCloseDialog}
            autoFocus
            disabled={disableClose}
          >
            تایید <TickCircle size={17} />
          </Button>
        </DialogActions>
      </Dialog>
      {pay_el}
    </div>
  );
}
export default InvoicePayment;
