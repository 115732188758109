import { ToastContainer, toast } from "react-toastify";
import { User, Edit2 } from "iconsax-react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { cn } from "../../lib/utils";
import React, { useEffect, useState } from "react";
import userinfo_api from "../../api/UserInfo";
import CallBackTelBot_api from "../../api/CallBackTelBot";
import deleteFromTelegramUser_api from "../../api/DeleteFromTelegramUser";
import userInfoEdit_api from "../../api/UserInfoEdit";

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};
const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

function Userinfo({ SetUserInfo }) {
  const [name, setName] = useState(null);
  const [telegram_uid, setTelegramUID] = useState(null);
  const [phone, setPhone] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [nameParameter, setNameParameter] = useState("");

  const handleGoToLink = (link) => {
    window.open(link, "_blank");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (
      file &&
      file.size <= 5 * 1024 * 1024 &&
      /\.(jpg|jpeg|png)$/i.test(file.name)
    ) {
      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      alert(
        "فقط فایل‌های png, jpg, و jpeg با حداکثر حجم ۵ مگابایت مجاز هستند."
      );
    }
  };

  const handleInputChange = (event) => {
    setNameParameter(event.target.value);
  };

  const UserInfo_data = async (telltest) => {
    const user_data = await userinfo_api();
    if (user_data[1] === 200) {
      setName(user_data[0].data.name);
      setNameParameter(user_data[0].data.name);
      setPhone(user_data[0].data.phone);
      if (telltest) {
        if (user_data[0].data.telegram_uid !== null) {
          if (telegram_uid === "") {
            toast.success("اکانت تلگرام متصل شد");
          }
        } else {
          if (telegram_uid === "") {
            toast.warning("اکانت تلگرام متصل نشد");
          }
        }
      }
      setTelegramUID(
        user_data[0].data.telegram_uid === null
          ? ""
          : user_data[0].data.telegram_uid
      );
      user_data[0].data.profile !== null && user_data[0].data.profile !== ""
        ? setPreviewUrl(
            `https://backyard.daniibtc.com/${user_data[0].data.profile}`
          )
        : setPreviewUrl(null);

      SetUserInfo({
        user: {
          name: user_data[0].data.name,
          email: user_data[0].data.phone,
          image:
            user_data[0].data.profile !== null &&
            user_data[0].data.profile !== ""
              ? `https://backyard.daniibtc.com/${user_data[0].data.profile}`
              : null,
          balance:
            user_data[0].data.balance !== null ? user_data[0].data.balance : 0,
        },
      });
    } else if (user_data[1] === 401) {
      window.location.href = "/Login?call_back=/dashboard";
    }
  };

  const SaveUserData = async () => {
    if (nameParameter !== null && nameParameter !== "") {
      if (selectedFile === null && nameParameter === name) {
        toast.warning("تغییری وجود ندارد");
        return null;
      }
      const data_saveUserData = await userInfoEdit_api(
        nameParameter,
        selectedFile
      );
      if (data_saveUserData[1] === 200) {
        UserInfo_data(false);
        toast.success("تغییرات انجام شد");
      } else {
        toast.error("مشکلی پیش آمده");
      }
    } else {
      toast.warning("نام نمیتواند خالی باشد");
    }
  };

  useEffect(() => {
    UserInfo_data(false);
  }, []);

  return (
    <div className="w-full flex flex-col justify-center items-center gap-[60px] dark">
      <span className="font-bold text-[24px]">اطلاعات کاربر</span>
      <div className="w-full flex flex-col justify-center items-center gap-[30px]">
        <div
          onClick={() => {
            document.getElementById("hiddenFileInput").click();
          }}
          className="relative w-[102px] h-[102px] rounded-full bg-[#313131] flex flex-row items-center justify-center cursor-pointer"
        >
          {previewUrl !== null ? (
            <img
              className="w-[100px] h-[100px] rounded-full object-cover"
              src={previewUrl}
              alt=""
            />
          ) : (
            <User className="w-[65px] h-[65px]" />
          )}
          <div className="absolute right-0 bottom-0 rounded-full p-2 bg-[#505050]">
            <Edit2 />
          </div>
        </div>

        <div className="w-[300px] flex flex-col gap-[10px]">
          <input
            type="file"
            id="hiddenFileInput"
            accept=".jpg, .jpeg, .png"
            className="hidden"
            onChange={handleFileChange}
          />
          <LabelInputContainer className="mb-4 flex flex-col">
            <Label className="flex" htmlFor="name_id">
              نام و نام خانوادگی
            </Label>
            <Input
              dir="rtl"
              id="name_id"
              placeholder="دانیال مختوم"
              type="text"
              defaultValue={name}
              value={nameParameter}
              onChange={handleInputChange}
            />
          </LabelInputContainer>
          <div className="flex flex-row items-center gap-[10px]">
            <button
              className="w-[100px] bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
              onClick={() => {
                UserInfo_data(true);
              }}
            >
              بررسی
              <BottomGradient />
            </button>
            {telegram_uid !== null && telegram_uid !== "" ? (
              <button
                className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                onClick={async () => {
                  const delete_user_date = await deleteFromTelegramUser_api();
                  if (delete_user_date[1] === 200) {
                    toast.success("اکانت با موفقیت حذف شد");
                    UserInfo_data(false);
                  } else {
                    toast.error("مشکلی پیش آمده با پشتیبانی تماس بگیرید");
                  }
                }}
              >
                حذف حساب تلگرام
                <BottomGradient />
              </button>
            ) : (
              <button
                className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                onClick={async () => {
                  const link_data = await CallBackTelBot_api();
                  if (link_data[1] === 200) {
                    toast.info("در حال ارسال به ربات...");
                    handleGoToLink(link_data[0].data.link);
                  } else {
                    toast.error("مشکلی پیش آمده لطفا مجدد تلاش کنید");
                  }
                }}
              >
                اتصال حساب تلگرام
                <BottomGradient />
              </button>
            )}
          </div>
          <button
            className="bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            onClick={() => {
              SaveUserData();
            }}
          >
            ذخیره
            <BottomGradient />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Userinfo;
